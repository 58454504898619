const breakingNewsNotificationsStyle = Object.freeze({
  kicker: {
    text: 'Breaking',
  },
  arrow: {
    rotate: 180,
  },
});

export default breakingNewsNotificationsStyle;
