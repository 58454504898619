const breakingNewsStyle = Object.freeze({
  buttonVariant: 'primaryOpaque',
  wrapperStyle: ({ theme, }) => ({ margin: '4rem', }),
  buttonBoxModel: ({ theme, }) => ({ hp: 3, vp: 1, }),
  stripController: {
    iconBackMiscStyles1: null,
    iconBackMiscStyles2: { transform: 'rotate(180deg)', },
  },
});

export default breakingNewsStyle;
