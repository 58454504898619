const paywallStyle = Object.freeze({
  illustration: 'imageHdc',
  fontFamily: 'commercial',
  type: 2,
  topBannerLayout: {
    margin: [ { until: 's', value: '3rem 0', }, { from: 's', until: 'm', value: '5rem 0 -2rem 0', }, { from: 'm', value: '5rem 0 2rem 0', }, ],
    gridTemplateAreas: [ { until: 's', value: '"content image"', }, { from: 's', value: '"image content"', }, ],
    gridTemplateColumns: [ { until: 's', value: '1fr 25rem', }, { from: 's', value: '35rem 1fr', }, ],
  },
});

export default paywallStyle;
