const contactUsStyle = Object.freeze({
  needMoreHelp: Object.freeze({
    buttonVariant: 'neutralOpaque',
    buttonColor: [ 'neutral' , '-1' ],
    selectVariant: 'primaryContactUs',
  }),
  subjectButton: Object.freeze({
    paddingBottom: '5rem',
    imageStyle: Object.freeze({
      width: '100%',
      marginTop: '0',
    }),
    extend: ({ theme, }) => ({
      size: [
        theme.type(0, { untilBp: 's', }),
        theme.type(1, { fromBp: 's', }),
      ],
    }),
  }),
  question: ({ theme, }) => ({
    extend: {
      extend: [
        theme.type(0, { untilBp: 'xl', }),
      ],
    },
  }),
  icon: Object.freeze({
    transform: 'rotate(180deg)',
  }),
  thankYouForm: Object.freeze({
    fontSize: ({ theme, }) => ({
      extend: [
        theme.type(0, { untilBp: 'm', }),
        theme.type(2, { fromBp: 'm', }),
      ],
    }),
    extendBlock: ({ theme, }) => ({
      extend: [
        theme.type(2, { untilBp: 'm', }),
        theme.type(4, { fromBp: 'm', }),
        theme.mq({ from: 'm', }, { paddingRight: '7rem', paddingLeft: '7rem', marginTop: '10%', }),
        theme.mq({ until: 'm', }, { marginTop: '6rem', marginBottom: '6rem', }),
      ],
    }),
  }),
});

export default contactUsStyle;
