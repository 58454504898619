export default Object.freeze({
  textSpacing: [ { until: 's', value: '4rem', }, { from: 's', value: '4rem', }, ],
  wrapper: {
    backgroundColor: [ 'primary', ],
    color: [ 'white', ],
    font: 'alt',
    miscStyles: {
      paddingStart: [ { until: 's', value: '3rem', }, { from: 's', value: '7rem', }, ],
      paddingEnd: [ { until: 's', value: '3rem', }, { from: 's', value: '7rem', }, ],
      paddingTop: [ { until: 's', value: '5rem', }, { from: 's', value: '8rem', }, ],
      paddingBottom: [ { until: 's', value: '5rem', }, { from: 's', value: '8rem', }, ],
      maxWidth: '90rem',
    },
  },
  logo: {
    size: [ { until: 's', value: 2.1, }, { from: 's', value: 2.4, }, ],
    color: [ 'secondary', '-2', ],
    miscStyles: {
      position: 'relative',
      display: 'block',
      margin: [ { from: 's', value: 'auto', }, ],
    },
  },
  illustration: {
    color: [ 'secondary', 'base', ],
    miscStyles: {
      width: '100%',
      height: '100%',
    },
  },
  header: {
    color: [ 'white', ],
    miscStyles: {
      maxWidth: '45rem',
      // textTransform: 'uppercase',
      marginTop: [ { until: 's', value: '4rem', }, { from: 's', value: '6rem', }, ],
      marginBottom: '4rem',
      type: [ { until: 's', value: 3, }, { from: 's', value: 3, }, ],
    },
  },
  link: {
    color: [ 'white', ],
    fontWeight: 700,
  },
  footer: {},
  actionButton: {
    boxModel: {
      vp: 1,
    },
    miscStyles: {
      type: -1,
      textTransform: 'uppercase',
    },
  },
  closeButton: {
    color: [ 'secondary', '-2', ],
  },
});
