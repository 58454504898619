const quoteStyle = Object.freeze({
  fontWeight: 700,
  visualElementHeight: Object.freeze([
    Object.freeze({
      until: 'l',
      value: '1.5rem',
    }),
    Object.freeze({
      from: 'l',
      until: 'xl',
      value: 'calc(1rem + 1px)',
    }),
    Object.freeze({
      from: 'xl',
      value: 'calc(1rem + 2px)',
    }),
  ]),
  visualElementMarginBottom: '1rem',
  imageMarginBottom: '1rem',
  iconQuoteSize: 7,
  iconQuoteMarginBottom: '1rem',
  citeVerticalPadding: 0,
  type: [
    { until: 'l', value: 3, },
    { from: 'l', value: 2, },
  ],
  citeType: [
    { until: 'l', value: 1, },
    { from: 'l', until: 'xl', value: 0, },
    { from: 'xl', value: -1, },
  ],
});

export default quoteStyle;
