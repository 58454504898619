const shareBarPopup = Object.freeze({
  illustration: 'balls',
  illustrationTransform: {
    x: '-39.55%',
    y: '-57.24%',
  },
  illustrationSizes: [
    { until: 's', value: 60, },
    { from: 's', until: 'xl', value: 80, },
    { from: 'xl', value: 85, },
  ],
  textColor: 'neutral',
  textWidth: [
    { until: 's', value: '35rem', },
    { from: 's', until: 'xl', value: '40rem', },
    { from: 'xl', value: '45rem', },
  ],
  fontStack: 'commercial',
  titleType: [
    { until: 's', value: 2, },
    { from: 's', until: 'xl', value: 3, },
    { from: 'xl', value: 4, },
  ],
  subtitleType: [
    { until: 's', value: -1, },
    { from: 's', until: 'xl', value: 0, },
    { from: 'xl', value: 1, },
  ],
  overlayBgColor: 'rgba(0,0,0,0.6)',
  closeBtnColor: [ 'neutral', '-10', ],
});

export default shareBarPopup;
