import fontStacks from './fontStacks';

const captionStyles = Object.freeze({
  paddingTop: '1rem',
  fontFamily: fontStacks.alt,
  fontWeight: '700',
  captionColor: [ 'primary', 'base', ],
  backgroundColor: [ 'neutral', '-10', ],
  captionTypeSettings: [ { until: 'xl', value: -2, }, { from: 'xl', value: -3, }, ],
  creditStyles: Object.freeze({
    fontWeight: '400',
    creditTypeSettings: [ { until: 'xl', value: -2, }, { from: 'xl', value: -3, }, ],
  }),
});

export default captionStyles;
