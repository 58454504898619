const footerStyle = Object.freeze({
  fontStack: 'alt',
  borderWidth: '1px',
  lines: 2,
  borderStyle: 'solid',
  footerMobileBorderStyle: Object.freeze({
    borderWidth: '1px',
    lines: 2,
    borderStyle: 'solid',
  }),
  footerDesktopPaddingTop: '8rem',
});

export default footerStyle;
