const specialPromotionStyle = Object.freeze({
  fontSize: [ { until: 's', value: -1, }, { from: 'xl', value: -1, }, ],
  padding: '1rem 1rem 1rem 2rem',
  whiteSpace: 'inherit',
  primary: Object.freeze({
    button: 'neutralOpaque',
    icon: [ 'secondary', '-2', ],
  }),
  primaryInverse: Object.freeze({
    button: 'secondary',
    icon: [ 'tertiary', '-4', ],
  }),
});

export default specialPromotionStyle;
