import config from 'config';
import fontStacksWebView from './fontStacksWebView';

const assetPrefix = config.has('assetPrefix') ? config.get('assetPrefix') : '';

const fallbackFont = '"Helvetica Neue", Helvetica,Arial, sans-serif';

const fontStacks = Object.freeze({
  webfonts: [
    [
      'Merriweather',
      [
        `${assetPrefix}/static/fonts/merriweatherLatin-300.woff2`,
        `${assetPrefix}/static/fonts/merriweatherLatin-300.woff`,
      ],
      { fontWeight: 200, fontDisplay: 'fallback', localAlias: [ 'Merriweather Light', ], },
    ],
    [
      'Merriweather',
      [
        `${assetPrefix}/static/fonts/merriweatherLatin-regular.woff2`,
        `${assetPrefix}/static/fonts/merriweatherLatin-regular.woff`,
      ],
      { fontWeight: 400, fontDisplay: 'fallback', localAlias: [ 'Merriweather Regular', ], },
    ],
    [
      'Merriweather',
      [
        `${assetPrefix}/static/fonts/merriweatherLatin-700.woff2`,
        `${assetPrefix}/static/fonts/merriweatherLatin-700.woff`,
      ],
      { fontWeight: 700, fontDisplay: 'fallback', localAlias: [ 'Merriweather Bold', ], },
    ],
  ],
  base: `"Merriweather",${fallbackFont}`,
  alt: fallbackFont,
  commercial: fallbackFont,
  infographics: fallbackFont,
  ...fontStacksWebView,
});

export default fontStacks;
