import fontStacks from "./fontStacks";

const abnerStyle = Object.freeze({
  quoteRotate: 180,
  titleFontFamily: fontStacks.alt,
  opHead: {
    teaserHeaderTypeScale: [ { until: 'xl', value: -1, }, { from: 'xl', value: -2, }, ],
    stackedAboveMobilePadding: '2rem 2rem',
  },
  editorialTeaser: {
    headerTypeScale: [ { until: 's', value: -1, }, { from: 's', value: 1, }, ],
    authorsTypeScale: [ { until: 's', value: -2, },
      { until: 'xl', value: -1, },
      { from: 'xl', value: -1, }, ],
    padding: [ { until: 's', value: '3rem', }, { from: 's', value: '2rem', }, ],
  },
  abnerPaginator: {
    colTemplate: [ { from: 'l', value: '4fr 5fr 5fr 5fr 5fr', }, ],
  },
  quoteTeaserHeaderTypeScale: [ { until: 'xl', value: -1, }, { from: 'xl', value: -2, }, ],
  editorialParagraph: ({ theme, }) => ({
    size: [ theme.type(-1, { fromBp: 's', untilBp: 'l', }),
      theme.type(0, {
        fromBp: 'l',
        untilBp: 'xl',
        lines: 3.5,
      }),
      theme.type(-1, { fromBp: 'xl', }),
      theme.mq({ until: 's', }, { display: 'none', }),
      theme.mq({ from: 's', until: 'l', }, { height: '16rem', }),
      theme.mq({ from: 'l', }, { height: '21rem', }),
    ], }),
});

export default abnerStyle;
