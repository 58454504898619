const timelineArticleStyle = Object.freeze({
  titleType: [
    { until: 's', value: 4, },
    { from: 's', until: 'l', value: 7, },
    { from: 'l', until: 'xl', value: 9, },
    { from: 'xl', value: 8, },
  ],
  itemDateType: 0,
  subTitleType: [
    { until: 's', value: 0, },
    { from: 's', until: 'xl', value: 2, },
    { from: 'xl', value: 1, },
  ],
  titleMarginBottomXL: '3rem',
  titleMarginTopL: '2rem',
  titleMarginTopM: '3rem',
  titleColor: ({ theme, }) => theme.color('primary'),
  dateMarginBottomL: '2rem',
  dateMarginBottomM: '-1rem',
});

export default timelineArticleStyle;
