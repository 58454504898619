const mastheadStyle = Object.freeze({
  fontStack: 'alt',
  fontWeight: 400,
  hamburgerMarginEnd: '2rem',
  searchButtonMarginEnd: 0,
  articlePageLogoSize: [ { until: 's', value: 3, }, { from: 's', value: 2.5, }, ],
  typeScale: -2,
  typeScaleLoggedIn: -1,
  separatorWidth: '2px',
  hamburgerSize: 3,
  focusBorderStyle: undefined,
});

export default mastheadStyle;
