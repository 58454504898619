// NOTE : this style is for pushwoosh custom pop up, the color need to be explicit
const subscribePopupStyle = Object.freeze({
  subscribeBtnBgColor: '#0a1633',
  askLaterBtnTextColor: '#0a1633',
  iconBgc: '#2064ff',
  fontWeight: '500',
  textColor: '#2d2d2d',
  padding: '16px 16px 2px 0px',
  justifyContent: 'space-evenly',
});

export default subscribePopupStyle;
