import fontStacks from './fontStacks';

const liveBlogArticleStyle = Object.freeze({
  updatesButtonTop: Object.freeze({
    default: '0',
    withOsaka: [
      { until: 'xl', value: '25rem', },
      { from: 'xl', value: '23rem', },
    ],
    withStickyMasthead: '8.5rem',
  }),
  timeHeadlineStyle: true,
  fontFamily: fontStacks.alt,
  captionMiscStyles: Object.freeze({ backgroundColor: 'white', }),
  gradient: 'var(--main-gradient)',
});

export default liveBlogArticleStyle;
