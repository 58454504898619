const tldrStyle = Object.freeze({
  subscribeButtonStyle: {
    marginBottom: "1.5rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  tldrHeader: {
    flexDirection: "row-reverse",
  },
});

export default tldrStyle;
