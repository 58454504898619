const osakaStyle = Object.freeze({
  top: isLabelArticle => [ { until: 'xl', value: 'calc(6rem + 3px)', }, { from: 'xl', value: 'calc(6rem + 4px)', }, ],
  hideNextItemLink: true,
  fontStack: 'alt',
  outbrainLogoRight: '-2rem',
  outbrainFooterWidth: 'fit-content',
  outbrainFooterPadding: 0,
  articleFooterMarginStart: 0,
  articlePadding: 0,
  itemPadding: '4rem',
  itemPaddingLtoXl: '2rem',
  itemPaddingLtoXlInner: '4rem',
  itemPaddingUntilL: '2rem',
  articleImgSpacing: '2rem',
  typeUntilXl: -1,
  typeFromXl: -2,
});

export default osakaStyle;
