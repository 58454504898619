export const listStyle = Object.freeze({
  header: {
    verticalType: [
      { until: 's', value: { step: 1, lines: 7, }, },
      { from: 's', until: 'xl', value: 1, },
      { from: 'xl', value: 0, },
    ],
    type: [
      { until: 's', value: { step: 1, lines: 5, }, },
      { from: 's', until: 'l', value: 2, },
      { from: 'l', value: 2, },
    ],
  },
});

export const listViewHeader = Object.freeze({
  title: {
    iconBackTransform: 'rotate(180deg)',
  },
});

export const verticalList = Object.freeze({
  displayBottomText: false,
  teaser: {
    headerTypeScale: isFirst => [
      { until: 's', value: -1, },
      { from: 's', until: 'xl', value: -1, },
      { from: 'xl', value: -2, },
    ],
    kickerTypeScale: isFirst => [
      { until: 's', value: -1, },
      { from: 's', until: 'xl', value: -1, },
      { from: 'xl', value: -2, },
    ],
  },
});

export const benderStyle = Object.freeze({
  mainTitle: Object.freeze({
    text: 'כתבות שאולי פספסתם',
    fontSize: [
      { until: 'm', value: 1, },
      { from: 'm', value: 2, },
    ],
  }),
  title: Object.freeze({
    fontSize: [
      { until: 'm', value: -2, },
      { from: 'm', until: 'l', value: -1, },
      { from: 'l', value: 0, },
    ],
  }),
  image: Object.freeze({
    aspect: 'vertical',
    width: '400',
    quality: 'auto',
  }),
});

export const marcoStyle = Object.freeze({
  teaser: Object.freeze({
    headerTypeScale: [
      { until: 's', value: -1, },
      { from: 's', until: 'xl', value: 1, },
      { from: 'xl', value: 0, },
    ],
  }),
});

export const michelleStyle = Object.freeze({
  showOnMobile: false,
  showAuthor: false,
  showTime: false,
});

export const robertoStyle = Object.freeze({
  teaser: Object.freeze({
    headerTypeScale: [
      { until: 's', value: 3, },
      { from: 's', until: 'l', value: 5, },
      { from: 'l', value: 7, },
    ],
    kickerTypeScale: [
      { until: 's', value: -1, },
      { from: 's', until: 'l', value: 0, },
      { from: 'l', value: -1, },
    ],
    subtitleTypeScale: [
      { from: 's', until: 'l', value: 1, },
      { until: 'xl', value: 2, },
      { from: 'xl', value: { step: 2, lines: 5, }, },
    ],
    footerTypeScale: [
      { until: 's', value: -3, },
      { from: 's', until: 'l', value: -2, },
      { from: 'l', value: -2, },
    ],
    titleVariant: 'tertiary',
    hdcTitleFontFamily: true,
    showImageMediumBp: false,
  }),
});

export const zappStyle = Object.freeze({
  listViewPadding: [
    { until: 's', value: '0 2rem', },
    { from: 's', value: '0 4rem 4rem', },
  ],
  dfpItemGridRowStart: 3,
  teaser: Object.freeze({
    gridGap: [
      { until: 's', value: '1rem', },
      { from: 's', until: 'l', value: '2rem', },
      { from: 'l', value: '1rem', },
    ],
    headerTypeScale: [
      { until: 's', value: -1, },
      { from: 's', until: 'l', value: 0, },
      { from: 'l', value: -1, },
    ],
    headerTypeScaleLabel: [
      { until: 's', value: 0, },
      { from: 's', until: 'l', value: 1, },
      { from: 'l', value: 0, },
    ],
    footer: {
      showTime: false,
      showRank: false,
      showCommentsCount: false,
    },
  }),
});

export const pazuzuStyle = Object.freeze({
  teaser: Object.freeze({
    headerTypeScale: [
      { until: 's', value: { step: -1, lines: 3, }, },
      { from: 's', until: 'xl', value: 1, },
      { from: 'xl', value: -1, },
    ],
    headerTypeScaleLabel: [
      { until: 's', value: { step: 0, lines: 3, }, },
      { from: 's', until: 'l', value: 1, },
      { from: 'l', value: 0, },
    ],
    footer: {
      showRank: false,
      showCommentsCount: false,
      showTime: false,
    },
  }),
});

export const leonardoStyle = Object.freeze({
  layoutContainerMiscStyle: {
    paddingTop: null,
  },
  listViewMiscStyle: ({ theme, }) => ({
    borderTop: [
      {
        from: 's',
        until: 'l',
        value: [ '2px', 2, 'solid', theme.color('primary', '-5'), ],
      },
      { from: 'l', value: [ '2px', 4, 'solid', theme.color('primary', '-5'), ], },
    ],
    borderBottom: [
      {
        from: 's',
        until: 'l',
        value: [ '2px', 2, 'solid', theme.color('primary', '-5'), ],
      },
      { from: 'l', value: [ '2px', 4, 'solid', theme.color('primary', '-5'), ], },
    ],
  }),
});

export const boxyStyle = Object.freeze({
  layoutRowBgc: ({ theme, }) => theme.color('neutral', '-1'),
  boxyItem: ({ theme, }) => ({
    themeType: [
      theme.type(2, { untilBp: 'l', lines: 6, }),
      theme.type(3, { fromBp: 'l', untilBp: 'xl', lines: 7, }),
      theme.type(2.2, { fromBp: 'xl', lines: 5, }),
    ],
  }),
  boxyLabelItem: ({ theme, }) => ({
    themeType: [
      theme.type(3, { untilBp: 'l', lines: 6, }),
      theme.type(5, { fromBp: 'l', untilBp: 'xl', lines: 7, }),
      theme.type(6, { fromBp: 'xl', lines: 8, }),
    ],
  }),
});

export const calculonStyle = Object.freeze({
  calculonTeasers: {
    headerTypeScale: [
      { until: 'l', value: -1, },
      { from: 'l', until: 'xl', value: 0, },
      { from: 'xl', value: -2, },
    ],
    footer: {
      showTime: false,
      showCommentsCount: false,
      showAuthor: true,
      miscStyles: { padding: '0 2rem 1rem', },
    },
    teaser1: {
      headerTypeScale: [
        { until: 's', value: 1, },
        { from: 's', until: 'xl', value: 0, },
        { from: 'xl', value: -1, },
      ],
      headerWrapperMiscStyles: {
        padding: [
          { until: 'l', value: '0 2rem', },
          { from: 'l', value: '2rem 2rem 0', },
        ],
      },
      footer: {
        showTime: false,
        showCommentsCount: false,
        miscStyles: { padding: '0 2rem 1rem', },
      },
    },
    teaser2: {
      pictureSourceAspect: 'square',
      headerWrapperMiscStyles: {
        marginInlineStart: [ { from: 's', value: '1rem', }, ],
      },
      footerMiscStyles: {
        marginInlineStart: [ { from: 's', value: '1rem', }, ],
      },
    },
    teaser3: {
      rowGap: '0rem',
      teaserHeaderMiscStyles: {
        marginInlineStart: [ { from: 's', value: '1rem', }, ],
      },
      footerMiscStyles: {
        marginInlineStart: [ { from: 's', value: '1rem', }, ],
      },
    },
    teaser4: {
      rowGap: '0rem',
      headerWrapperMiscStyles: {
        marginInlineStart: [ { from: 'l', value: '1rem', }, ],
      },
      footerMiscStyles: { from: 'l', value: '1rem', },
    },
    teaser5: {
      rowGap: '0rem',
      headerWrapperMiscStyles: {
        padding: '1rem 2rem 0',
      },
    },
  },
});

export const slugsStyle = Object.freeze({
  showExtraLinks: false,
  mainTeaser: {
    rowGap: [
      { until: 'l', value: '2rem', },
      { from: 'l', value: '1rem', },
    ],
    headerWrapperMiscStyles: { paddingTop: [ { from: 'l', value: '1rem', }, ], },
    footer: {
      showTime: false,
      showCommentsCount: false,
      miscStyles: { display: 'flex', justifyContent: 'center', },
    },
  },
  twoUpTeaser: {
    colGap: [
      { until: 's', value: '1rem', },
      { from: 's', until: 'l', value: '2rem', },
      { from: 'l', value: '2rem', },
    ],
    rowGap: '1rem',
    headerTypeScale: [
      { until: 's', value: -1, },
      { from: 's', until: 'xl', value: 0, },
      { from: 'xl', value: -1, },
    ],
    headerWrapperMiscStyles: { paddingTop: [ { from: 'l', value: '1rem', }, ], },
    footer: {
      showCommentsCount: false,
      showTime: false,
    },
  },
  textualTeaser: {
    rowGap: '1rem',
    headerTypeScale: [
      { until: 's', value: -1, },
      { from: 's', value: 0, },
    ],
    headerWrapperMiscStyles: { paddingTop: [ { from: 'l', value: '1rem', }, ], },
    footer: {
      showCommentsCount: false,
      showTime: false,
    },
  },
});

export const panucciStyle = Object.freeze({
  colGap: '2rem',
  headerTypeScale: hasImage => [
    { until: 's', value: -1, },
    { from: 's', until: 'l', value: hasImage ? -1 : 1, },
    { from: 'l', until: 'xl', value: hasImage ? 0 : 1, },
    { from: 'xl', value: hasImage ? -1 : 0, },
  ],
  footer: {
    showCommentsCount: false,
    showTime: false,
  },
  horizontalTeaser: {
    headerTypeScale: [
      { until: 's', value: -1, },
      { from: 's', until: 'l', value: -1, },
      { from: 'l', until: 'xl', value: 0, },
      { from: 'xl', value: -1, },
    ],
  },
});

export const barbadosStyle = Object.freeze({
  displayListViewHeader: true,
  listView: {
    areaTemplate: [
      {
        until: 's',
        value: `
        "he"
        "t1"
        "t2"
        "t3"
        "t4"
        "t5"
        `,
      },
      {
        from: 's',
        until: 'l',
        value: `
        "he he"
        "t1 t2"
        "t3 t3"
        "t4 t4"
        "t5 t5"
        `,
      },
      {
        from: 'l',
        value: `
        "he he he"
        "t1 t2 t3"
        "t1 t2 t4"
        "t1 t2 t5"
        `,
      },
    ],
    rowGap: [
      { until: 's', value: '1.5rem', },
      { from: 's', until: 'l', value: '4rem', },
      { from: 'l', value: '1.5rem', },
    ],
    padding: [
      { until: 's', value: '6rem 2rem 0rem', },
      { from: 's', value: '0 4rem 4rem', },
    ],
  },
  displayTeasersMobileHdc: isMobile => !!isMobile,
  teaser1: {
    rowGap: '1.5rem',
    headerTypeScale: [
      { until: 's', value: 1, },
      { from: 's', until: 'l', value: 0, },
      { from: 'l', value: -1, },
    ],
    footer: {
      showCommentsCount: false,
    },
  },
  teaser2: {
    colGap: [ { from: 's', value: '2rem', }, ],
    rowGap: [
      { from: 's', until: 'l', value: '1rem', },
      { from: 'l', value: '2rem', },
    ],
    headerTypeScale: [
      { until: 's', value: -1, },
      { from: 's', until: 'l', value: 0, },
      { from: 'l', value: -1, },
    ],
    headerWrapperMiscStyles: {
      padding: [
        { until: 's', value: '1rem 1rem', },
        { from: 'l', until: 'xl', value: '1rem 0 0', },
      ],
    },
    footer: {
      showCommentsCount: false,
    },
  },
});

export const weekendStyle = Object.freeze({
  sectionMiscStyles: {
    marginBottom: '8rem',
  },
  teaser1N2: {
    headerTypeScale: [ { until: 's', value: -1, }, { from: 's', value: 1, }, ],
    headerWrapperMiscStyles: { alignSelf: [ { from: 's', value: 'center', }, ], },
  },
  teaser3: {
    areasTemplate: [
      {
        until: 's', value: `
        "media  media  media"
        ".     content     ."
        ".        .        ."
        ".     footer      ."
        ".        .        ."
        ".        .        ."
          `,
      },
      {
        from: 's', value: `
          ".    content    ."
          ".       .       ."
          ".    footer     ."
          "media media media"
          `,
      },
    ],
    rowTemplate: [ { until: 's', value: '1fr auto auto auto auto auto', }, { from: 's', value: '1fr auto auto auto', }, ],
    headerWrapperMiscStyles: {
      marginTop: [
        { from: 's', until: 'l', value: '6rem', },
        { from: 'l', value: '8rem', },
      ],
      padding: [ { until: 's', value: '2rem', }, ],
    },
    footer: {
      miscStyles: {
        marginBottom: [
          { from: 's', until: 'l', value: '4rem', },
          { from: 'l', value: '6rem', },
        ],
      },
    },
  },
  teaser4: {
    lineBgMiscStyles: ({ theme, }) => theme.type(1, { lines: 5, fromBp: 'xl', }),
    hMiscStyles: {
      paddingInlineStart: '2rem',
      paddingInlineEnd: '2rem',
    },
  },
  teaser5: {
    headerTypeScale: 1,
    headerWrapperMiscStyles: {
      alignSelf: 'end',
      padding: [ { until: 's', value: '2rem', }, ],
    },
    areasTemplate: [
      {
        until: 's',
        value: `
          "media  media  media"
          ".     content     ."
          ".        .        ."
          ".     footer      ."
          ".        .        ."
          ".        .        ."
          `,
      },
      {
        from: 's',
        value: `
          ".   .  .   .     ."
          ".   .  .   .     ."
          ". media . content ."
          ". media . footer  ."
          ".   .  .   .     ."
          ".   .  .   .     ."`,
      },
    ],
  },
});

export const mousepadStyle = Object.freeze({
  teaser: {
    footer: {
      showCommentsCount: false,
    },
  },
});

export const hawkingStyle = Object.freeze({
  hawkingMainTeaser: {
    colGap: [
      { from: 'xl', value: '2rem', },
      { until: 'xl', value: '1rem', },
    ],
    headerTypeScale: [
      { until: 's', value: 1, },
      { from: 's', until: 'l', value: 1, },
      { from: 'l', until: 'xl', value: 0, },
      { from: 'xl', value: 1, },
    ],
    headerWrapperMiscStyles: {
      marginBlockStart: [ { from: 'xl', value: '1rem', }, ],
      paddingInlineStart: [ { until: 's', value: '1rem', }, ],
    },
    footer: {
      showTime: false,
      showRank: false,
      showCommentsCount: false,
      miscStyles: { paddingInlineStart: [ { until: 's', value: '1rem', }, ], },
    },
  },
  teaser: {
    headerWrapperMiscStyles: { paddingInlineStart: '1rem', },
    headerTypeScale: [
      { until: 's', value: -1, },
      { from: 'xl', value: -1, },
    ],
    footer: {
      showAuthor: true,
      showCommentsCount: false,
      miscStyles: { paddingInlineStart: '1rem', },
    },
  },
});

export const santaStyle = Object.freeze({
  teaser: {
    headerTypeScale: [
      { until: 's', value: -1, },
      { from: 's', until: 'xl', value: 0, },
      { from: 'xl', value: -1, },
    ],
    headerTypeScaleLabel: [
      { until: 's', value: 0, },
      { from: 's', until: 'l', value: 1, },
      { from: 'l', value: 0, },
    ],
    footer: {
      showTime: false,
      showCommentsCount: false,
      miscStyles: { marginTop: [ { from: 's', until: 'l', value: '1rem', }, ], },
    },
  },
});

export const tateStyle = Object.freeze({
  lineBgMiscStyles: ({ theme, }) => theme.type(2, { lines: 5, fromBp: 'xl', }),
  teaser: {
    colGap: [
      { until: 's', value: '2rem', },
      { from: 's', until: 'l', value: '4rem', },
      { from: 'l', value: '2rem', },
    ],
    rowGap: [
      { until: 's', value: '0.5rem', },
      { from: 's', until: 'l', value: '2rem', },
      { from: 'l', value: '0rem', },
    ],
    paddingTop: isFirst => [
      { until: 's', value: isFirst ? '0rem' : '1rem', },
      { from: 's', until: 'l', value: '3rem', },
      { from: 'l', until: 'xl', value: '1rem', },
      { from: 'xl', value: '2rem', },
    ],
    paddingBottom: isFirst => [
      { until: 's', value: isFirst ? '0rem' : '1rem', },
      { from: 's', until: 'l', value: '1.5rem', },
      { from: 'l', until: 'xl', value: '1rem', },
      { from: 'xl', value: '2rem', },
    ],
    footer: {
      showTime: true,
      showCommentsCount: false,
    },
  },
});

export const conradAndWongStyle = Object.freeze({
  headerTypeScale: isConrad => [
    { until: 's', value: { step: 2, lines: 5, }, },
    { from: 's', until: 'l', value: { step: 5, lines: 7, }, },
    { from: 'l', until: 'xl', value: { step: 4, lines: 6, }, },
    { from: 'xl', value: { step: 4, lines: 5, }, },
  ],
});

export const donbotStyle = Object.freeze({
  teasers: {
    mainTeaser: {
      rowGap: [ { from: 'xl', value: '2rem', }, ],
      contentMiscStyles: {
        padding: [
          { until: 'xl', value: '2rem 2rem', },
          { from: 'xl', value: '0rem 2rem', },
        ],
      },
      footer: {
        showTime: false,
        showCommentsCount: false,
      },
    },
    teaser: {
      headerTypeScale: [
        { until: 's', value: -1, },
        { from: 'l', until: 'xl', value: { step: -1, lines: 3, }, },
        { from: 'xl', value: -1, },
      ],
      footer: {
        showAuthor: true,
        showCommentsCount: false,
      },
    },
  },
});

export const spotStyle = Object.freeze({
  gradient: 'var(--main-gradient)',
});
