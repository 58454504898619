const marketingBannerStyle = Object.freeze({
  fontStack: 'alt',
  // textTransform: 'uppercase',
  topStrip: Object.freeze({
    typeScale: -1,
    buttonTypeScale: -2,
  }),
  bottomRuller: Object.freeze({
    defaultView: 'salmonBubbles',
  }),
});

export default marketingBannerStyle;
