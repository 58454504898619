import fontStacks from './fontStacks';

const appPromotionToolStyle = Object.freeze({
  actionButtonVariant: 'primaryOpaque',
  declineButtonVariant: 'primary',
  fontFamily: fontStacks.commercial,
});


export default appPromotionToolStyle;
