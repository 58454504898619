const variationA = Object.freeze({
  titleType: [
    { until: 's', value: 4, },
    { from: 's', until: 'l', value: 8, },
    { from: 'l', value: 10, },
  ],
  subTitleType: [
    { until: 's', value: 0, },
    { from: 's', until: 'xl', value: 2, },
    { from: 'xl', value: 1, },
  ],
  shareBarMarginTop: [
    { from: 'm', until: 'l', value: '4rem', },
    { from: 'l', value: '6rem', },
  ],
  magazineHeaderMarginTop: [
    { from: 's', until: 'l', value: '3rem', },
  ],
});

const variationB = Object.freeze({
  headerTextMaxWidth: '95rem',
  titleType: [
    { until: 's', value: 4, },
    { from: 's', until: 'l', value: 8, },
    { from: 'l', until: 'xl', value: 7, },
    { from: 'xl', value: 9, },
  ],
  subTitleType: [
    { until: 's', value: 0, },
    { from: 's', until: 'l', value: 2, },
    { from: 'l', value: 1, },
  ],
  shareBarMarginTop: [
    { from: 'm', until: 'l', value: '4rem', },
    { from: 'l', until: 'xl', value: '6rem', },
    { from: 'xl', value: '4rem', },
  ],
});

const faceArticleStyle = Object.freeze({
  variationA,
  variationB,
  headerMetaPaddingTop: [
    { until: 's', value: '2rem', },
    { from: 's', until: 'l', value: '4rem', },
  ],
  headerMetaPaddingBottom: [
    { until: 's', value: '2rem', },
    { from: 's', until: 'l', value: '4rem', },
  ],
});

export default faceArticleStyle;
