const layoutStyle = Object.freeze({
  contPaddingS: 3,
  startColumnWidthL: 23,
  startColumnWidthXL: 30,
  startColumnPadding: 3,
  startColumnPaddingXL: 3,
  sectionTitleATypeScaleFromL: 2,
  sectionTitleALines: 1,
});

export default layoutStyle;
