const paywallMidpageStyle = Object.freeze({
  textColor: [ 'primary', ],
  separatorColor: [ 'secondary', ],
  separatorLogoColor: [ 'secondary', ],
  separatorIconSize: 5,
  fontStack: 'commercial',
  textType: 2,
  buttonType: 0,
  inputVariant: 'primary',
  buttonVariant: 'primaryOpaque',
  closeArticleText: {
    fontWeight: 700,
    color: [ 'secondary', ],
    type: [
      { until: 'xl', value: 1, },
      { from: 'xl', value: 0, },
    ],
  },
});

export default paywallMidpageStyle;
