import fontStacks from './fontStacks';

const topNewsStyle = Object.freeze({
  fontFamily: fontStacks.alt,
  href: '/misc/breaking-news',
  contentNameSuffix: '',
  sectionClasses: ({ theme, }) => ({
    border: null,
    paddingTop: null,
    paddingBottom: '1rem',
  }),
});


export default topNewsStyle;
