const searchEngineStyle = Object.freeze({
  fontStack: 'alt',
  logoSize: [ { until: 's', value: 3, }, { from: 's', value: 4, }, ],
  inputFontWeight: 300,
  resultsInputFontWeight: 300,
  placeholderFontWeight: 300,
  hotTopicsHeadlineMobileFontWeight: '700',
});

export default searchEngineStyle;
