const ePaperStyle = Object.freeze({
  layoutStyle: ({ theme, }) => ({
    display: [ { until: 's', value: 'flex', }, { from: 's', value: 'block', }, ],
    fontFamily: theme.fontStacks[theme.framedFont],
    color: theme.color('primary'),
  }),
  layoutContainerStyle: ({ theme, }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '9rem',
    ...theme.mq({ until: 's', }, {
      maxWidth: '90vw',
      paddingTop: '5rem',
      paddingBottom: '7rem',
    }),
    ...theme.mq({ from: 's', }, {
      paddingTop: '7rem',
    }),
  }),
  imageStyle: ({ theme, }) => ({
    extend: [
      theme.mq({ until: 's', }, {
        maxWidth: '44rem',
      }),
      theme.mq({ from: 's', }, {
        maxWidth: '49rem',
      }),
    ],
  }),
  blockStyle: ({ theme, }) => ({
    textAlign: 'center',
    extend: [
      theme.mq({ until: 's', }, {
        paddingTop: '3rem',
        marginBottom: '3.5rem',
      }),
      theme.mq({ from: 's', }, {
        paddingTop: '4rem',
        marginBottom: '4rem',
      }),
    ],
  }),
  titleStyle: ({ theme, }) => ({
    extend: [
      theme.type(3, { until: 's', }),
      theme.type(5, { from: 's', }),
    ],
  }),
  textStyle: ({ theme, }) => ({
    marginTop: '1rem',
    extend: [
      theme.type(2, { from: 's', }),
    ],
  }),
  buttonStyle: ({ theme, }) => ({
    ...theme.type(-1),
  }),
  loginLinkStyle: ({ theme, }) => ({
    marginStart: '1rem',
    textDecoration: 'underline',
    textDecorationSkip: 'ink',
    width: 'fit-content',
    color: theme.color('secondary'),
    '&:hover': {
      color: theme.color('secondary', '+1'),
    },
    '&:active': {
      color: theme.color('secondary', '+1'),
    },
    '&:focus': {
      color: theme.color('secondary', '+1'),
    },
  }),
});

export default ePaperStyle;
